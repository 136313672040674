@property --l1 {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}

.box {
  /*  needed for firefox to have a valid output */
  --l1: 0%;
  --yellow: #ffe6a5;
  --pink: #db57b6;
  display: inline-block;
  transition: --l1 4s;
  background: linear-gradient(
    180deg,
    var(--yellow) calc(var(--l1) - 150%),
    var(--pink) calc(var(--l1) - 50%),
    var(--yellow) var(--l1),
    var(--pink) calc(var(--l1) + 100%)
  );
  animation: a 4s linear infinite;
}

@keyframes a {
  from {
    --l1: 0%;
  }
  to {
    --l1: 150%;
  }
}
